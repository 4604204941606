define('ember-simple-infinite-scroller/services/-infinite-scroller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var jQuery = Ember.$;
  exports.default = Service.extend({
    $document: function $document() {
      return jQuery(document);
    },
    $window: function $window() {
      return jQuery(window);
    }
  });
});