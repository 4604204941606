define('ember-rapid-forms/components/em-text', ['exports', 'ember-rapid-forms/templates/components/em-text', 'ember-rapid-forms/mixins/input-component'], function (exports, _emText, _inputComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend(_inputComponent.default, {
    layout: _emText.default,
    elementClass: null,
    property: null,
    label: null,
    name: null,
    placeholder: null,
    rows: null,
    cols: null,
    autofocus: null,
    readonly: null,
    autoresize: null,
    disabled: null,
    form: null
  });
});