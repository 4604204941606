define('ember-rapid-forms/mixins/has-property-validation', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var alias = Ember.computed.alias;
  var Mixin = Ember.Mixin;
  var isNone = Ember.isNone;
  var defineProperty = Ember.defineProperty;
  var computed = Ember.computed;
  exports.default = Mixin.create({
    init: function init() {
      this._super.apply(this, arguments);
      (true && !(!isNone(this.get('propertyName'))) && Ember.assert('propertyName is required.', !isNone(this.get('propertyName'))));

      defineProperty(this, 'errors', alias('model.errors.' + this.get('propertyName')));
    },


    status: computed('errors.length', {
      get: function get() {
        if (this.get('errors.length')) {
          return 'error';
        } else {
          return 'success';
        }
      }
    })
  });
});