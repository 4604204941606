define('ember-particles/components/ember-particles/component', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var on = _ember['default'].on;

  /* global pJS */
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['ember-particles'],
    _hasElement: false,
    _particles: null,

    _setupHasElement: on('didInsertElement', function () {
      this._hasElement = true;
      this.updateParticles();
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      if (this._hasElement) {
        this.updateParticles();
      }
    },

    destroyParticles: function destroyParticles() {
      var oldParticles = this._particles;
      if (oldParticles) {
        _destroyParticles(oldParticles);
        this._particles = null;
      }
    },

    updateParticles: function updateParticles() {
      var element = get(this, 'element');

      // Destroy the old particles instance if exists.
      this.destroyParticles();

      removeChildren(element);
      createChildCanvas(element);

      this._particles = new pJS(element.id, this.getAttr('options'));
    }
  });

  function removeChildren(node) {
    while (node.firstChild) {
      var child = node.firstChild;
      node.removeChild(child);
    }
  }

  /*
    We can't use the particlesJS hook to generate a canvas as it doesn't
    return an instance of the pJS object. So, we'll do it manually.
   */
  function createChildCanvas(node) {
    var canvasElement = document.createElement('canvas');
    canvasElement.className = 'particles-js-canvas-el';
    canvasElement.style.width = '100%';
    canvasElement.style.height = '100%';
    node.appendChild(canvasElement);
  }

  function _destroyParticles(particles) {
    particles.pJS.fn.vendors.destroypJS();
  }
});