define('ember-rapid-forms/components/form-group', ['exports', 'ember-rapid-forms/templates/components/form-group'], function (exports, _formGroup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    layout: _formGroup.default,
    tagName: '',
    property: null
  });
});