define('ember-rapid-forms/mixins/ember-cp-validations-helper', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin;
  var isNone = Ember.isNone;
  var canInvoke = Ember.canInvoke;
  exports.default = Mixin.create({
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      // when there is a validation error copy its message into the format we currently understand
      this.get('validations.validatableAttributes').forEach(function (va) {
        _this.addObserver(va, 'validations.attrs.' + va + '.messages', function () {
          _this._copyErrors(_this, va);
        });
      });
    },
    validate: function validate() {
      var _get;

      return (_get = this.get('validations')).validate.apply(_get, arguments).then(this._copyEachErrors.bind(this));
    },
    _copyEachErrors: function _copyEachErrors(validations) {
      var _this2 = this;

      var modelErrors = this.get('errors');
      if (!isNone(modelErrors) && canInvoke(modelErrors, 'add')) {
        this.get('validations.validatableAttributes').forEach(function (va) {
          _this2._copyErrors(_this2, va);
        });
      }
      return validations;
    },


    // gets called when a message is added (that means there is a validation-error)
    _copyErrors: function _copyErrors(model, attribute) {
      if (model.currentState.stateName === 'root.loaded.saved' || model.currentState.stateName === 'root.deleted.saved') {
        return;
      }

      var modelErrors = model.get('errors');
      var wasEmpty = modelErrors.get('isEmpty');

      if (modelErrors.get(attribute)) {
        modelErrors._remove(attribute);
      }

      var messages = this.get('validations.attrs.' + attribute + '.messages');

      // manually add them to the errorObject
      if (messages && messages.length > 0) {
        messages.forEach(function (m) {
          modelErrors._add(attribute, m);
        });
      }

      var isEmpty = modelErrors.get('isEmpty');

      // trigger the method whether a model is now valid or not
      if (wasEmpty && !isEmpty) {
        modelErrors.trigger('becameInvalid');
      } else if (!wasEmpty && isEmpty) {
        modelErrors.trigger('becameValid');
      }
    }
  });
});