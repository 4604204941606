define('ember-rapid-forms/mixins/input-component', ['exports', 'ember-rapid-forms/mixins/has-id', 'ember-rapid-forms/mixins/has-property', 'ember-rapid-forms/mixins/has-property-validation'], function (exports, _hasId, _hasProperty, _hasPropertyValidation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var alias = Ember.computed.alias;
  var oneWay = Ember.computed.oneWay;
  var Mixin = Ember.Mixin;
  var deprecate = Ember.deprecate;
  var defineProperty = Ember.defineProperty;
  var observer = Ember.observer;
  var computed = Ember.computed;
  exports.default = Mixin.create(_hasProperty.default, _hasPropertyValidation.default, _hasId.default, {
    validations: true,
    validationIcons: oneWay('form.validationIcons'),

    hasSuccess: computed('status', 'canShowErrors', {
      get: function get() {
        return this._hasStatus('success');
      }
    }),

    hasWarning: computed('status', 'canShowErrors', {
      get: function get() {
        return this._hasStatus('warning');
      }
    }),

    hasError: computed('status', 'canShowErrors', {
      get: function get() {
        return this._hasStatus('error');
      }
    }),

    shouldShowErrors: computed('canShowErrors', 'helpText', {
      get: function get() {
        var text = this.get('helpText') || "";
        return text.length > 0 && this.get('canShowErrors');
      }
    }),

    helpText: computed('text', 'errors.firstObject', {
      get: function get() {
        return this.get('errors.firstObject.message') || this.get('errors.firstObject') || this.get('text');
      }
    }),

    controlWrapper: computed('form.formLayout', 'labelInControl', {
      get: function get() {
        if (this.get('form.formLayout') === 'horizontal') {
          if (this.get('labelInControl')) {
            return 'col-sm-offset-2 col-sm-10';
          }

          return 'col-sm-10';
        }

        return null;
      }
    }),

    propertyOptions: computed('property', 'validations.attrs.@each.options', function () {
      var property = this.get('property');

      return this.get('model.validations.attrs.' + property + '.options') || false;
    }),

    required: computed('propertyOptions.presence.presence', function () {
      return this.get('propertyOptions.presence.presence') || false;
    }),

    formSubmitted: observer('form.isSubmitted', 'form.showErrorsOnSubmit', 'errors.length', function () {
      if (this.get('errors.length') && this.get('form.showErrorsOnSubmit') && this.get('form.isSubmitted')) {
        this.set('canShowErrors', true);
      }
    }),

    hideValidationsOnFormChange: observer('form', 'form.model', function () {
      this.set('canShowErrors', false);
    }),

    init: function init() {
      this._super.apply(this, arguments);
      if (this.get('form.showErrorsOnRender')) {
        this.set('canShowErrors', true);
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var hasForm = !!this.get('form');
      if (hasForm && !this.get('hasSetForm')) {
        this.set('hasSetForm', true);
      } else if (hasForm && !this.get('hasSetForm')) {
        deprecate('Please use the new form.input helper defined in 1.0.0beta10', hasForm, { id: 'ember-rapid-forms.yielded-form', until: 'v1.0' });
        defineProperty(this, 'form', alias('formFromPartentView'));
        this.set('hasSetForm', true);
      }
    },


    /*
    Observes the helpHasErrors of the help control and modify the 'status' property accordingly.
     */

    focusIn: function focusIn() {
      if (this.get('form.showErrorsOnFocusIn')) {
        return this.set('canShowErrors', true);
      }
    },


    /*
    Listen to the focus out of the form group and display the errors
     */
    focusOut: function focusOut() {
      return this.set('canShowErrors', true);
    },


    /*
    Listen to the keyUp of the form group and display the errors if showOnKeyUp is true.
     */
    keyUp: function keyUp() {
      if (this.get('showOnKeyUp')) {
        return this.set('canShowErrors', true);
      }
    },
    _hasStatus: function _hasStatus(type) {
      var status = this.get('validations') && this.get('status') === type && this.get('canShowErrors');
      this.set(type, type);
      return status;
    }
  });
});