define('ember-rapid-forms/mixins/in-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var Mixin = Ember.Mixin;
  exports.default = Mixin.create({
    formFromPartentView: computed({
      get: function get() {
        var parentView = this.get('parentView');

        while (parentView) {
          if (parentView.get('tagName') === 'form') {
            return parentView;
          }
          parentView = parentView.get('parentView');
        }

        return assert('Cannot find form');
      }
    }),
    model: computed('form', 'form.model', {
      get: function get() {
        return this.get('form.model');
      }
    })
  });
});