define('pro-slugs/utils/slug', ['exports'], function (exports) {
  exports['default'] = slug;
  function replaceSpecialCharacters(string) {
    var replacementMap = [['ä', 'ae'], ['ö', 'oe'], ['ü', 'ue'], ['ß', 'ss']];

    replacementMap.forEach(function (pair) {
      string = string.replace(pair[0], pair[1]);
    });

    return string;
  }

  function slug(string) {
    var slug = string;

    slug = slug.toLowerCase().trim();
    slug = replaceSpecialCharacters(slug);
    slug = slug.replace(/[\s_\-]+/g, '-');
    slug = slug.replace(/[^a-z0-9\-]/g, '');

    return slug;
  }
});