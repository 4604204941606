define('ember-rapid-forms/components/em-checkbox-input', ['exports', 'ember-rapid-forms/templates/components/em-checkbox-input'], function (exports, _emCheckboxInput) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var alias = Ember.computed.alias;
  var Component = Ember.Component;
  exports.default = Component.extend({
    layout: _emCheckboxInput.default,
    tagName: '',
    label: alias('checkboxComponent.label'),
    form: alias('checkboxComponent.form'),
    inputId: alias('checkboxComponent.inputId'),
    labelClass: alias('checkboxComponent.labelClass'),
    model: alias('checkboxComponent.model'),
    property: alias('checkboxComponent.property'),
    disabled: alias('checkboxComponent.disabled'),
    required: alias('checkboxComponent.required'),
    autofocus: alias('checkboxComponent.autofocus'),
    readonly: alias('checkboxComponent.readonly'),
    title: alias('checkboxComponent.title'),
    elementClass: alias('checkboxComponent.elementClass'),
    name: alias('checkboxComponent.name')
  });
});