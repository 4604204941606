define('ember-rapid-forms/mixins/has-property', ['exports', 'ember-rapid-forms/mixins/has-id'], function (exports, _hasId) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var alias = Ember.computed.alias;
  var Mixin = Ember.Mixin;
  var assert = Ember.assert;
  var defineProperty = Ember.defineProperty;
  var computed = Ember.computed;
  exports.default = Mixin.create(_hasId.default, {
    property: undefined,

    propertyName: computed('property', 'formComponent.property', {
      get: function get() {
        if (this.get('property')) {
          return this.get('property');
        } else if (this.get('formComponent.property')) {
          return this.get('formComponent.property');
        } else {
          return assert('Property could not be found.');
        }
      }
    }),

    init: function init() {
      this._super.apply(this, arguments);
      defineProperty(this, 'errors', alias('model.errors.' + this.get('propertyName')));
    }
  });
});