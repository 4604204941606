define('ember-particles/helpers/ember-particles-options', ['exports', 'ember'], function (exports, _ember) {
  exports.emberParticlesOptions = emberParticlesOptions;
  var set = _ember['default'].set;
  var get = _ember['default'].get;
  var isEmpty = _ember['default'].isEmpty;

  function emberParticlesOptions(params) {
    var result = {};
    params.forEach(function (path, idx) {
      if (idx % 2 === 0) {
        makePathIfNotExist(result, '', path.split('.'));
        set(result, path, params[idx + 1]);
      }
    });
    return result;
  }

  exports['default'] = _ember['default'].Helper.helper(emberParticlesOptions);

  function makePathIfNotExist(obj, path, remainingParts) {
    var nextPath = remainingParts.shift();

    // If there are no remaining parts after this one, no need to proceed.
    if (remainingParts.length === 0) {
      return;
    }

    nextPath = path.length > 0 ? path + '.' + nextPath : nextPath;
    if (isEmpty(get(obj, nextPath))) {
      set(obj, nextPath, {});
    }

    makePathIfNotExist(obj, nextPath, remainingParts);
  }
});