define('ember-rapid-forms/components/em-checkbox', ['exports', 'ember-rapid-forms/templates/components/em-checkbox', 'ember-rapid-forms/mixins/input-component'], function (exports, _emCheckbox, _inputComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend(_inputComponent.default, {
    layout: _emCheckbox.default,
    validationIcons: false,
    validations: false,
    labelInControl: true,

    groupClass: computed('form.formLayout', {
      get: function get() {
        if (this.get('form.formLayout') !== 'horizontal') {
          return 'checkbox';
        }
        return 'form-group';
      }
    }),

    checkboxWrapper: computed('form.formLayout', {
      get: function get() {
        if (this.get('form.formLayout') === 'horizontal') {
          return 'checkbox';
        }
        return null;
      }
    })
  });
});