define('ember-rapid-forms/components/form-group-control', ['exports', 'ember-rapid-forms/templates/components/form-group-control'], function (exports, _formGroupControl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    layout: _formGroupControl.default,
    tagName: ''
  });
});