define('ember-rapid-forms/components/em-form-group', ['exports', 'ember-rapid-forms/mixins/has-property-validation', 'ember-rapid-forms/mixins/has-property', 'ember-rapid-forms/templates/components/em-form-group'], function (exports, _hasPropertyValidation, _hasProperty, _emFormGroup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var alias = Ember.computed.alias;
  var Component = Ember.Component;
  var EmberObject = Ember.Object;
  var computed = Ember.computed;
  var getOwner = Ember.getOwner;
  var isPresent = Ember.isPresent;
  exports.default = Component.extend(_hasProperty.default, _hasPropertyValidation.default, {
    tagName: 'div',
    groupClass: 'form-group',
    layout: _emFormGroup.default,
    classNameBindings: ['groupClass', 'hasSuccess', 'hasWarning', 'hasError', 'validationIcons:has-feedback', 'required'],
    attributeBindings: ['disabled'],
    successIcon: 'check',
    warningIcon: 'exclamation-triangle',
    errorIcon: 'times',
    hasSetForm: false,

    inputComponent: EmberObject.create(),

    inputId: alias('inputComponent.inputId'),
    yieldInLabel: alias('inputComponent.yieldInLabel'),
    labelInControl: alias('inputComponent.labelInControl'),
    hasError: alias('inputComponent.hasError'),
    hasSuccess: alias('inputComponent.hasSuccess'),
    hasWarning: alias('inputComponent.hasWarning'),
    shouldShowErrors: alias('inputComponent.shouldShowErrors'),
    helpText: alias('inputComponent.helpText'),
    required: alias('inputComponent.required'),
    hideValidationsOnFormChange: alias('inputComponent.hideValidationsOnFormChange'),
    labelWrapperClass: alias('inputComponent.labelWrapperClass'),
    labelClass: alias('inputComponent.labelClass'),
    help: alias('inputComponent.help'),
    controlWrapper: alias('inputComponent.controlWrapper'),
    validationIcons: alias('inputComponent.validationIcons'),
    form: alias('inputComponent.form'),

    i18n: computed(function () {
      return getOwner(this).lookup('service:i18n');
    }),

    validationIcon: computed('status', 'shouldShowErrors', {
      get: function get() {
        if (!this.get('shouldShowErrors')) {
          return;
        }
        switch (this.get('status')) {
          case 'success':
            return this.get('successIcon');
          case 'warning':
          case 'warn':
            return this.get('warningIcon');
          case 'error':
            return this.get('errorIcon');
          default:
            return null;
        }
      }
    }),

    label: computed('inputComponent.label', function () {
      var i18n = this.get('i18n');
      var label = this.get('inputComponent.label');

      if (label) {
        return label;
      } else if (isPresent(i18n)) {
        var property = this.get('property');
        var modelName = this.get('model.constructor.modelName');
        var key = void 0;

        if (modelName) {
          key = modelName + '.' + property;
        } else {
          key = property;
        }

        if (i18n.exists(key)) {
          return i18n.t(key);
        }
      }
    })
  });
});