define('ember-click-outside/components/click-outside', ['exports', 'ember-click-outside/mixins/click-outside', 'ember-click-outside/templates/components/click-outside', 'ember-component', 'ember-evented/on', 'ember-runloop'], function (exports, _clickOutside, _clickOutside2, _emberComponent, _on, _emberRunloop) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  exports.default = _emberComponent.default.extend(_clickOutside.default, {
    layout: _clickOutside2.default,

    clickOutside: function clickOutside(e) {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }

      var exceptSelector = this.get('except-selector');
      if (exceptSelector && $(e.target).closest(exceptSelector).length > 0) {
        return;
      }

      var action = this.get('action');
      if (typeof action !== 'undefined') {
        action(e);
      }
    },


    _attachClickOutsideHandler: (0, _on.default)('didInsertElement', function () {
      this._cancelOutsideListenerSetup = (0, _emberRunloop.next)(this, this.addClickOutsideListener);
    }),

    _removeClickOutsideHandler: (0, _on.default)('willDestroyElement', function () {
      (0, _emberRunloop.cancel)(this._cancelOutsideListerSetup);
      this.removeClickOutsideListener();
    })
  });
});