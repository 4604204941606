define('ember-rapid-forms/components/em-select', ['exports', 'ember-rapid-forms/templates/components/em-select', 'ember-rapid-forms/mixins/input-component'], function (exports, _emSelect, _inputComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var get = Ember.get;
  var run = Ember.run;
  exports.default = Component.extend(_inputComponent.default, {
    layout: _emSelect.default,
    validationIcons: false,
    propertyIsModel: false,
    property: null,
    content: null,
    elementClass: null,
    selection: null,
    optionValuePath: 'id',
    optionLabelPath: 'value',
    optionDisabledPath: null,
    prompt: null,
    disabled: null,
    autofocus: null,
    size: 0,
    optionGroupLabelPath: null,
    optionGroupContentPath: null,

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      run.schedule('sync', this, function () {
        if (_this.get('model.isLoading')) {
          _this.get('model').on('didLoad', function () {
            _this._setValue();
          });
        } else {
          _this._setValue();
        }
      });
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this._addComputedSelectedValue();
    },


    actions: {
      change: function change() {
        this._setValue();
      }
    },

    _addComputedSelectedValue: function _addComputedSelectedValue() {
      var content = this.get('content');

      if (!content) {
        this.set('content', []);
      }

      // set it to the correct value of the selection
      this.selectedValue = computed('model.' + this.get('property'), function () {
        var propertyIsModel = this.get('propertyIsModel');
        var value = this.get('model.' + this.get('property'));
        if (propertyIsModel && value !== null && value !== undefined) {
          var optionValuePath = this.get('optionValuePath');
          if (value.get === undefined) {
            value = value[optionValuePath];
          } else {
            value = value.get(optionValuePath);
          }
        }
        return value;
      });
    },
    _setValue: function _setValue() {
      var selectedEl = this.$('select')[0];
      var model = this.get('model');
      if (model) {

        var selectedIndex = selectedEl.selectedIndex;

        if (selectedIndex < 0 || !this.get('content.length')) return;

        // check whether we show prompt the correct to show index is one less
        // when selecting prompt don't change anything
        if (this.get('prompt')) {
          if (selectedIndex !== 0) {
            selectedIndex--;
          } else {
            if (this.get('property')) {
              model.set(this.get('property'), null);
            }
            return;
          }
        }

        var selectedID = void 0,
            selectedValue = void 0;

        if (this.get('optionGroupContentPath')) {
          var selectedElement = selectedEl.options[selectedIndex + 1];
          var optGroup = selectedElement.parentNode;
          var optGroupOptions = optGroup.children;
          var positionInOptGroup = Array.prototype.indexOf.call(optGroupOptions, selectedElement);
          var optionGroup = this.get('content').filterBy(this.get('optionGroupLabelPath'), optGroup.label)[0];

          selectedValue = get(optionGroup, this.get('optionGroupContentPath')).objectAt(positionInOptGroup);
        } else {
          var content = this.get('content');
          selectedValue = content.objectAt(selectedIndex);
        }

        if (this.get('optionDisabledPath') && get(selectedValue, this.get('optionDisabledPath'))) {
          // if it is disabled don't do anything
          return;
        }

        var optionValuePath = this.get('optionValuePath');
        var propertyIsModel = this.get('propertyIsModel');

        if (propertyIsModel) {
          selectedID = selectedValue;
        } else {
          selectedID = selectedValue[optionValuePath];
        }

        model.set(this.get('property'), selectedID);
        var changeAction = this.get('action');
        if (changeAction) {
          changeAction(selectedID);
        }
      }
    }
  });
});