define('ember-rapid-forms/mixins/has-id', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var Mixin = Ember.Mixin;
  exports.default = Mixin.create({
    inputId: computed('cid', 'property', 'id', {
      get: function get() {
        if (this.get('cid')) {
          return this.get('cid');
        } else if (this.elementId && this.get('property')) {
          return this.get('property') + '-' + this.elementId;
        }
      }
    })
  });
});