define('ember-rapid-forms/mixins/has-class-calc', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var Mixin = Ember.Mixin;
  exports.default = Mixin.create({
    horiClassCalc: computed('form.isHorizontal', {
      get: function get() {
        return this._classCalc('form.isHorizontal', 'horiClass');
      }
    }),

    inlineClassCalc: computed('form.formLayout', {
      get: function get() {
        return this._classCalc('form.isInline', 'inlineClass');
      }
    }),

    _classCalc: function _classCalc(condition, cssClass) {
      if (this.get(condition) && this.get(cssClass)) {
        return this.get(cssClass);
      }
    }
  });
});